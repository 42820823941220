<template>
  <div
  :class="{ 'border border-teal-green':checkIfTestIsSelected}"
    class="w-full h-full flex flex-col bg-white rounded-lg overflow-hidden shadow-lg md:shadow-card"
  >
    <div class="h-full flex flex-col justify-between">
      <div class="bg-white">
        <div
          v-if="
            !isPanel &&
            !props.clinic &&
            (!displayService ||
              globalStore.siteSettings.display_service_types_on_products)
          "
          :class="titleBarBackground(item, displayService)"
          class="px-4 py-1 flex justify-between items-center relative"
        >
          <span
            class="text-white text-[14px]"
            :class="displayService ? 'py-1' : ''"
            >{{
              displayService
                ? $t("common.availableAs")
                : item.service_type.title
            }}</span
          >
          <div
            :class="titleBarBackground(item, displayService)"
            v-if="!displayService"
            class="flex items-center justify-center absolute rounded-full -bottom-[17px] right-4 w-10 h-10"
          >
            <NuxtImg
              loading="lazy"
              :src="serviceIcon(item.service_type)"
              alt=""
              class="w-5 h-5"
              width="20"
              height="20"
            />
          </div>
          <div v-else class="flex justify-center space-x-4 py-1">
            <CommonTooltip :title="item.service_type.title">
              <NuxtImg
                loading="lazy"
                :src="serviceIcon(item.service_type)"
                alt=""
                class="w-5 h-5"
                width="20"
                height="20"
              />
            </CommonTooltip>
            <div v-for="serviceItem in item.related_services">
              <CommonTooltip :title="serviceItem.service_type.title">
                <NuxtImg
                  loading="lazy"
                  :src="serviceIcon(serviceItem.service_type)"
                  alt=""
                  class="w-5 h-5"
                  width="20"
                  height="20"
                />
              </CommonTooltip>
            </div>
          </div>
        </div>
        <div class="px-5 py-6 flex" :class="(!checkIfTestIsSelected)?'bg-terace-pool ':'bg-teal-green text-white'">
          <div
            class="w-full flex justify-between items-center text-[19px] leading-tight font-bold"
          >
            <span class="pr-2">{{ item.title }}</span>
            <span v-if="!displayService" class="whitespace-nowrap">{{
              $formatCurrency(item.price)
            }}</span>
          </div>
        </div>
        <div class="flex justify-between items-center -mt-4">
          <span
            v-if="item.test_type.title"
            class="bg-secondary text-white px-4 text-[10px] font-semibold leading-1 rounded-tr-full rounded-br-full py-[2px] mt-1 uppercase"
            >{{ item.test_type.title }}</span
          >
          <span
            v-if="item.most_popular"
            class="bg-golden-banner rounded-tl-full rounded-bl-full px-4 text-[10px] font-semibold leading-1 mt-1 py-[2px] uppercase"
            >{{ $t("common.mostPopular") }}</span
          >
        </div>
        <div class="p-5 pb-6 text-xs">
          <span
            class="text-sm text-dark-blue"
            v-if="item.infections.length > 0"
          >
            {{ infectionLabel }}
          </span>
          <div class="2 mt-2 grid grid-cols-2">
            <div
              v-for="infection in item.infections"
              class="py-1 flex items-start text-xs text-space-cadet"
              :key="infection.slug"
            >
              <CheckIcon class="w-4 h-4 mr-1 text-antigua" />
              {{ infection.title }}
            </div>
          </div>
        </div>
        <!--
        <div
          class="flex text-sm justify-center gap-4 bg-turquoise-lighter text-[#267476] border-b px-4 py-5 border-primary-light"
          v-if="showTurnAroundTime"
        >
          <NuxtImg src="/icons/time-machine.svg" alt="timemachine" />
          <span>{{
            `${item.turnaround_time}-${
              item.turnaround_time == 0 || item.turnaround_time == 1
                ? "day"
                : "days"
            } results turnaround time`
          }}</span>
        </div>
        -->
      </div>  
      <div class="bg-turquoise-lighter border-t border-primary-600">
        <div
          class="p-5 text-left text-sm leading-relaxed relative md:min-h-[140px]"
        >
          <div v-html="item.summary" />
        </div>
        
        <div class="px-4 pt-0 pb-4 text-center" v-if="!isPanel">
          <BaseButton
            v-if="clinic == true"
            @click="saveSelection(item)"
            :text="buttonText"
            :start_point="startPoint"
            class="w-full h-12 text-lg font-semibold"
          />
          
          <NuxtLinkLocale
            v-else
            :locale="switchBlogLocale()"
            :to="testProductUrl(item)"
          >
            <BaseButton
              :text="buttonText"
              :start_point="startPoint"
              class="w-full h-12 text-lg font-semibold"
            />
          </NuxtLinkLocale>
        </div>

        <div class="px-4 pb-4" v-else>
         
           <BaseButton
               :disabled="checkoutStore.testLists.length==maxTestList"
               v-if="!checkIfTestIsSelected"
              :text="$t('common.btnBookNow')"
              @click="addMultipleTest()"
              class="w-full h-12 text-lg font-semibold"
            />
            <BaseButton
              v-else
              variant="outlined"
              :text="$t('common.removeBooking')"
              @click="addMultipleTest()"
              class="bg-white w-full h-12 text-lg font-semibold border-4"
              buttonAllignment="end"
            >
              <NuxtImg src="/icons/delete-forever.svg" class="w-5 h-5"  alt="delete" />
            </BaseButton>
            
            <div class="text-center pt-4">
              <NuxtLinkLocale
                v-if="checkoutStore.testLists.length<1"
                :locale="switchBlogLocale()"
                :to="testProductUrl(item)"
                :text="$t('common.btnViewDetails')"
                class="text-primary h-4 font-bold text-[15px] decoration-[1.5px] underline-offset-2 hover:underline"
              >
              </NuxtLinkLocale>
            </div> 
        </div> 
      </div>
    </div>
  </div>
</template>
<script setup>
import { CheckIcon } from "@heroicons/vue/solid";
import { useCheckoutStore } from "~/stores/checkout";
import { useProductStore } from "~/stores/filter";
import { useGlobalStore } from "~/stores/global";
const route = useRoute();
const emit = defineEmits([
  "showCheckoutPanel",

]);
const productStore = useProductStore();
const checkoutStore = useCheckoutStore();
const maxTestList = checkoutStore.maxTestLists;
const { t } = useI18n();
const { $formatCurrency } = useNuxtApp();
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  clinic: {
    type: Object,
  },
  isPanel: Boolean,
  showTurnAroundTime: {
    type: Boolean,
    default: true,
  },
  viewTestButtonText: {
    type: String,
  },
  isHomePage: {
    type: Boolean,
    default: false,
  },
  displayService: {
    type: Boolean,
    default: false,
  },
  startPoint: {
    type: String,
    default: "unknown",
  },
  serviceSlug: {
    type: String,
    required: false,
  },
});
const toggleShowMore = ref(true);
const selectedClinic = ref(false);

if (props.clinic) {
  selectedClinic.value = true;
}
const paragraph = computed({
  get() {
    const p = props.item.summary;
    if (p.length > 100) {
      return truncate(p, 120);
    } else {
      return p;
    }
  },
});
const globalStore = useGlobalStore();

const checkIfTestIsSelected = computed(() => {
   return checkoutStore.testLists.find((data) => data.id == props.item.id);
});



const addMultipleTest = () => {
  checkoutStore.setCollection(props.item)
 
  emit('showCheckoutPanel')
  
}

const buttonText = computed(() => {
  return props.viewTestButtonText
    ? props.viewTestButtonText
    : props.item.service_type.slug == "clinic-test"
    ? t("common.viewAndBook")
    : t("common.viewAndOrder");
});

const infectionLabel = computed(() => {
  return props.item.infections.length == 1
    ? ( (props.item.primary_category.slug == 'sexual-health')
      ? t("common.testsforSingleInfection")
      : t("common.testsforSingle")
    )
    : ( (props.item.primary_category.slug == 'sexual-health')
      ? t("common.testsforInfections", { total: props.item.infections.length })
      : t("common.testsfor")
    );
});

const testProductUrl = (item) => {
  if(item.service_type.slug == 'everywhere-nurse-service') {
    return `/tests/${item.primary_category?item.primary_category.slug + "/" : ""}${item.slug}?slug=everywhere-nurse-service`;
  }
  return `/tests/${
    item.primary_category ? item.primary_category.slug + "/" : ""
  }${item.slug}${route.query.slug?`?slug=${route.query.slug}`:''}`;
};

function truncate(value, length) {
  if (value.length > length) {
    return value.substring(0, length) + "...";
  } else {
    return value;
  }
}

const titleBarBackground = (item, displayService) => {
  if (displayService) {
    return "bg-space-cadet";
  }
  let tBackgroundColor = "";
  if (item.service_type) {
    switch (item.service_type.slug) {
      case "clinic-test":
        tBackgroundColor = "bg-space-cadet";
        break;
      case "instant-test":
        tBackgroundColor = "bg-pantone";
        break;
      case "home-test-kit":
        tBackgroundColor = "bg-liberty";
        break;
      default:
        tBackgroundColor = "bg-antigua";
        break;
    }
  }
  return tBackgroundColor;
};

const serviceIcon = (service_type) => {
  let icon = "";
  if (service_type) {
    switch (service_type.slug) {
      case "everywhere-nurse-service":
        icon = "/icons/services/nurse-everywhere.svg";
        break;
      case "instant-test":
        icon = "/icons/services/clinic-instant.svg";
        break;
      case "home-test-kit":
        icon = "/icons/services/home-test.svg";
        break;
      default:
        icon = "/icons/services/clinic-test.svg";
        break;
    }
  }
  return icon;
};

const saveSelection = (item, type = '') => {

  if (selectedClinic.value) {
    
      const clinicID = (checkoutStore.getCheckoutSession.clinic_id != "everywhere")? props.clinic.id 
      : checkoutStore.getCheckoutSession.clinic_id
  
      checkoutStore.setSelectedClinicCookieData(clinicID, item.id)
      return navigateTo(
        localeUrlQuery({
          path: "/checkout",
          query: {
            clinic_id: clinicID,
            test_id: item.id,
            start_point: props.startPoint,
          },
        })
      );
    
  }  else if (item.service_type.slug === "everywhere-nurse-service" || !selectedClinic.value) {
  
    return   navigateTo(localeUri(testProductUrl(item)));
  } else {
    return testProductUrl(item) + `?slug=${route.query.slug}`;
  }
};
</script>
